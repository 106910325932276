<template>
  <div>
    <div class="mb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-1"
        @click="
          $router.push({
            name: 'admin-user-form',
            params: { user_id: 'new' },
          })
        "
      >
        Add User
      </b-button>

      <b-button-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-bind:class="[itemUserType == 'all' ? 'active' : '']"
          v-on:click="changeTableType('all')"
        >
          All Users
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-bind:class="[itemUserType == 'seller' ? 'active' : '']"
          v-on:click="changeTableType('seller')"
        >
          Seller(s)
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-bind:class="[itemUserType == 'admin' ? 'active' : '']"
          v-on:click="changeTableType('admin')"
        >
          Admin(s)
        </b-button>
      </b-button-group>
    </div>

    <b-card no-body>
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="6" offset-md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
                v-model="search"
                type="text"
                @input="onSearch"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- ALl Users table -->
      <b-table
        id="NewProductListTableId"
        ref="refNewProductListTable"
        :items="UserList"
        responsive
        :fields="tableColumns"
        primary-key="user_id"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        class="position-relative"
      >
        <!-- Column: Id -->
        <template #cell(index)="data">
          {{
            parseInt((pagination.currentPage - 1) * pagination.perPage) +
            data.index +
            1
          }}
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link
            :href="
              data.item.userRoleId == 1
                ? 'admin-items?token=' + data.item.user_id
                : ''
            "
          >
            {{ data.item.user_fname + " " + data.item.user_lname }}
          </b-link>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.user_email }}
            </span>
          </b-media>
        </template>

        <!-- payout -->
        <template #cell(payout)="data">
          <div class="d-flex align-items-center" :key="data">
            <!-- <span>
              {{
                data.item.user_country == ""
                  ? "Country Pending"
                  : data.item.user_country == "+91"
                  ? data.item.user_is_payu_pending == 1
                    ? "PayU Pending"
                    : "PayU Completed"
                  : data.item.user_is_stripe_pending == 1
                  ? "Stripe Pending"
                  : "Stripe Completed"
              }}
            </span> -->
            <span>
              {{
                data.item.user_country == "" ? "Country Pending" : (data.item.user_is_stripe_pending == 1 ? "Stripe Pending" : "Stripe Completed")
              }}
            </span>
            <a
              v-b-modal.payout-details-sm
              @click="showPayoutDetails(data.item)"
            >
              <b-avatar class="mr-1" variant="light-success">
                <feather-icon size="18" icon="EyeIcon" />
              </b-avatar>
            </a>
          </div>
        </template>

        <!-- Colums: Role -->
        <template #cell(role)="data">
          <div class="d-flex align-items-center" :key="data">
            <b-avatar class="mr-1" variant="light-danger">
              <feather-icon size="18" icon="UserIcon" />
            </b-avatar>
            <span>{{ getAdminRoleName(data.item.userRoleId) }}</span>
          </div>

          <!-- <div
            class="d-flex align-items-center"
            v-if="data.item.userRoleId == 2"
          >
            <b-avatar class="mr-1" variant="light-success">
              <feather-icon size="18" icon="CheckCircleIcon" />
            </b-avatar>
            <span>Admin</span>
          </div> -->
        </template>

        <!-- Column: Creation Date -->
        <template #cell(creation_date)="data">
          <span class="text-nowrap">
            {{ getHumanDate(data.item.user_created_at) }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon ml-1"
              @click="
                $router.push({
                  name: `admin-user-form`,
                  params: { user_id: data.item.user_id },
                })
              "
            >
              <feather-icon icon="EditIcon" />
            </b-button> -->

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon ml-1"
              @click="deleteUser(data.item.user_email, data.item.user_id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-pagination
        aria-controls="NewProductListTableId"
        v-model="pagination.currentPage"
        :total-rows="UserList.length"
        :per-page="pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>

    <b-modal
      id="payout-details-sm"
      cancel-variant="secondary"
      ok-only
      ok-title="Close"
      centered
      size="sm"
      title="User's Quick Payout Details"
    >
      <b-card-text>
        <p><b>Name:</b> {{ payoutDetails.name }}</p>
        <p><b>Email:</b> {{ payoutDetails.email }}</p>
        <!-- <p><b>Country:</b> {{ payoutDetails.country }}</p> -->
        <p><b>Payout Method:</b> {{ payoutDetails.method }}</p>
        <p><b>Pay Account ID:</b> {{ payoutDetails.account_id }}</p>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BButtonGroup,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import UserManagementServices from "@/apiServices/UserManagementServices";
import moment from "moment";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BCardText,
    BButtonGroup,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  setup() {},
  data() {
    return {
      AllUserList: [],
      UserList: [],
      search: "",
      itemUserType: "all",
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [10, 20, 30],
      },
      tableColumns: [
        "index",
        { key: "user_id", label: "User Id", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "payout", label: "Payout", sortable: true },
        { key: "role", label: "Type (Role)", sortable: true },
        { key: "creation_date", label: "Created Date", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      payoutDetails: {
        name: null,
        email: null,
        country: null,
        method: null,
        account_id: null,
      },
    };
  },
  computed: {},
  methods: {
    changeTableType(userType) {
      this.itemUserType = userType;
      if (userType === "all") {
        this.UserList = this.AllUserList;
        return;
      }
      this.UserList = this.AllUserList.filter((item) => {
        return item.user_type === userType;
      });
    },
    loadAllUsers() {
      UserManagementServices.getAllUsers()
        .then((response) => {
          this.AllUserList = response.data.data;
          this.UserList = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    deleteUser(email, user_id) {
      new swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const senData = { email: email, user_id: user_id };
          console.log(senData);

          UserManagementServices.deleteUser(senData)
            .then((response) => {
              console.log("All Users: " + JSON.stringify(response.data.data));

              if (response.data.data != null) {
                new swal({
                  icon: "success",
                  title: "Deleted!",
                  text: "User has been deleted.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                new swal({
                  title: "Cancelled",
                  text: "Failed to delete user :(",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
        } else if (result.dismiss === "cancel") {
          new swal({
            title: "Cancelled",
            text: "Your imaginary file is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    onSearch(e) {
      if (!e) this.UserList = this.AllUserList;
      this.UserList = this.AllUserList.filter((item) => {
        let name = item.user_fname + " " + item.user_lname;
        name = name.toLowerCase();
        e = e.toLowerCase();
        return name.includes(e);
      });
    },
    getAdminRoleName(uid) {
      let name = "";
      if (uid == 1) {
        name = "Seller";
      } else if (uid == 2) {
        name = "Super Admin";
      } else if (uid == 3) {
        name = "Admin L2";
      } else if (uid == 4) {
        name = "Admin L2";
      } else if (uid == 5) {
        name = "Admin L3";
      } else {
        name = "N/A";
      }
      return name;
    },
    showPayoutDetails(userData) {
      console.log(userData);
      this.payoutDetails.name = userData.user_fname + " " + userData.user_lname;
      this.payoutDetails.email = userData.user_email;
      //this.payoutDetails.country = userData.user_country;
      if (userData.user_country == "") {
        this.payoutDetails.method = "Country Pending...";
        this.payoutDetails.account_id = "N/A";
      } else if (userData.user_country == "+91") {
        //(userData.user_is_payu_pending == 1)
        this.payoutDetails.method = "PayU";
        this.payoutDetails.account_id =
          userData.user_is_payu_pending == 0
            ? userData.user_payu_details
            : "Pending";
      } else {
        //(userData.user_is_stripe_pending == 1)
        this.payoutDetails.method = "Stripe";
        this.payoutDetails.account_id =
          userData.user_is_stripe_pending == 0
            ? userData.user_stripe_id
            : "Pending";
      }
    },
  },
  beforeMount() {
    this.loadAllUsers();
  },
};
</script>

<style lang="scss" scoped>
.uppercase {
  text-transform: uppercase !important;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
</style>
